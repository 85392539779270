import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Vivek Kandathil',
  lang: '',
  description: '',
};

export const heroData = {
  title: '',
  name: 'Vivek Kandathil',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'http://vivek.kandathil.ca/files/Resume_VivekKandathil.pdf',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'prompttotutorial.jpg',
    title: 'Prompt To Tutorial',
    info: 'GPT-based video generation based on user prompts',
    info2: "Leverages OpenAI's GPT-4 to generate video scripts based on the provided topic, and uses Bing Image Search APIs + Azure Text to Speech to generate the visuals and voiceovers",
    url: 'https://github.com/vivekandathil/prompt-to-tutorial',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'listmaker.jpg',
    title: 'List Maker',
    info: 'A React Native mobile app that simplifies the process of creating grocery lists.',
    info2: '',
    url: '',
    repo: 'https://github.com/vivekandathil/ListMaker',
  },
  {
    id: nanoid(),
    img: '1000pushups.jpg',
    title: '1000 Push-ups',
    info: 'A React Native app that offers a fun way to keep track of push-ups (using your nose!)',
    info2: '',
    url: '',
    repo: 'https://github.com/vivekandathil/1000-Pushups',
  },
  {
    id: nanoid(),
    img: 'liveequationeditor.jpg',
    title: 'Live Equation Editor',
    info: 'A site for uploading handwritten equation images, automatically viewing the formatted equations, and evaluating them on WolframAlpha/Symbolab',
    info2: '',
    url: '',
    repo: 'https://github.com/vivekandathil/live-equation-editor',
  },
  {
    id: nanoid(),
    img: 'cars.jpg',
    title: 'Car Catalog',
    info: 'A JavaFX application that simplifies the process of researching vehicles online. The main menu includes a sorted table of vehicle names. After selecting a car, you can use a variety of tools to help refine your search (for example, the colour palette and price range slider). This data is used to query a variety of REST APIs to retrieve as much information as possible on the chosen car.',
    info2: '',
    url: '',
    repo: 'https://github.com/vivekandathil/CarTester',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'E-Mail',
  email: 'vivekandath@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/vivekandathil',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/vivekkandathil/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/vivekandathil/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false,
};
